<ng-container *ngIf="state === 'initial'">
  <form>
      <button *ngIf="!this.formData.meetingID" (click)="this.initNew()">Neue Abstimmung einrichten</button>
      <button *ngIf="this.isVoter()" (click)="this.joinAsVoter()">Abstimmung starten</button>
      <button *ngIf="this.isAdmin()" (click)="this.joinAsAdmin()">Als Administrator beitreten</button>
      <br/>
      <br/>
      Meeting-ID:
      <input type="text" name="meetingID" [(ngModel)]="this.formData.meetingID" />
  </form>
</ng-container>
<ng-container *ngIf="state === 'voter'">
  <div class="divInMiddle">
    Bitte verteilen Sie insgesamt {{this.participant.getDotsPerVoter()}} Punkte an die aus Ihrer Sicht wichtigsten Vorschläge. Wenn Sie möchten, können Sie einem Vorschlag dabei auch mehrere Punkte geben.<br/>
    <br/>
    Verbleibende Punkte: {{this.getRemainingDots()}}<br/>

    <form name="topVoteForm">
      <table>
        <tr *ngFor="let item of this.participant.voteSummary.stableItems; trackBy:itemId">
          <td style="text-align:right; min-width: 5em;">
            <app-dot [empty]="true" (click)="this.addDotFor(item.id)"></app-dot>
            <app-dot [empty]="false" *ngFor="let x of [].constructor(this.getDotCountFor(item.id));" (click)="this.removeDotFor(item.id)"></app-dot>
          </td>
          <td (click)="this.addDotFor(item.id)">
            <label for="{{item.id}}"><b>{{item.boldText}}</b>{{item.text}}</label>
          </td>
        </tr>
      </table><br/><br/>
      <div *ngIf="this.getRemainingDots() > 0">Die Abstimmung kann noch nicht abgesendet werden, weil noch nicht alle Punkte vergeben wurden.</div>
      <div *ngIf="this.getRemainingDots() < 0">Die Abstimmung kann nicht abgesendet werden, weil zu viele Punkte vergeben wurden. Klicken Sie auf einen Punkt, um ihn wieder zu löschen.</div>
      <button (click)="this.saveVote()" [disabled]="this.getRemainingDots() != 0">Abstimmung absenden</button>
    </form>
  </div>
</ng-container>
<ng-container *ngIf="state === 'summary'">
  <table class="styled-table">
    <thead>
      <tr>
        <th>Vorschlag</th>
        <th width="15em" class="right">Anzahl Stimmen</th>
      </tr>  
    </thead>
    <tbody>
      <tr *ngFor="let item of this.participant.voteSummary.sortedItems">
        <td><b>{{item.boldText}}</b>{{ item.text }}</td>
        <td class="right">{{ item.topVoteCount }}</td>
      </tr>  
    </tbody>
  </table>
  <br/>
  Anzahl Teilnehmer: {{this.participant.voteSummary.voterCount}}<br/>
  Anzahl Vorschläge: {{this.participant.voteSummary.sortedItems.length}}<br/>
  <ng-container *ngIf="this.isAdmin()">
    <br/>
    <form name="newItems">
      <textarea name="itemInput" placeholder="Hier neue Vorschläge durch Leerzeilen getrennt einfügen" rows="10" cols="100"></textarea><br/>
      <button (click)="this.addNewItems()">Neue Vorschläge hinzufügen</button>
    </form>
    <br/>
    <input name="dotsPerVoter" type="number" [value]="this.participant.getDotsPerVoter()" (input)="this.setDotsPerVoter($event.target)" />
    <br/>
    Admin-Link: <a [href]="this.getAdminLink()">{{ this.getAdminLink() }}</a><br/>
    Teilnehmer-Link: <a [href]="this.getVoterLink()">{{ this.getVoterLink() }}</a><br/>
  </ng-container>
  <br/>
  <a (click)="this.reconnect()">Neu verbinden ...</a><br/>
  <!--<ideenmesse-peer-status [peer]="this.peer"></ideenmesse-peer-status>-->
</ng-container>
