//import { RTCDB } from 'rtcdb';

import { HttpClient } from "@angular/common/http";
import { timer } from "rxjs";

interface ItemDto {
  boldText: string;
  text: string;
}

interface DbDto {
  time: number,
  items: any,
  topVotes: any,
  estimates: any,
  state: {state: string}
}

class VotesPerItem {
  public topVoteCount: number = 0;

  constructor(
    public readonly id: string,
    public readonly boldText: string, 
    public readonly text: string) {

  }

}

class VoteSummary {
  private items: Map<string, VotesPerItem> = new Map();
  private randomOrder: Map<string, number>;
  private voterCnt: number = 0;

  constructor(db: Db, randomOrder: Map<string, number>) {
    this.randomOrder = randomOrder;
    db.forEach('items', (id, dta) => {
      let sid = id as string;      
      this.items.set(sid, new VotesPerItem(sid, dta.boldText, dta.text));
    });
    db.forEach('topVotes', (id, dta) => {
      this.voterCnt++;
      let ids: string[] = dta.split(',');
      ids.forEach(itemId => {
        let itemVotes = this.items.get(itemId);
        if (itemVotes) {
          itemVotes.topVoteCount++;
        }  
      });
    });
  }

  public get sortedItems(): VotesPerItem[] {
    let list: VotesPerItem[] = [];
    this.items.forEach((value, key) => list.push(value));
    list.sort((a, b) => b.topVoteCount - a.topVoteCount);
    return list;
  }

  public get stableItems(): VotesPerItem[] {
    let list: VotesPerItem[] = [];
    this.items.forEach((value, key) => list.push(value));
    list.sort((a, b) => {
      let cmp = this.getRandomOrderFor(a.id) - this.getRandomOrderFor(b.id);
      if (cmp != 0) {
        return cmp;
      }
      return a.id.localeCompare(b.id)
    });
    return list;
  }

  private getRandomOrderFor(itemId: string): number {
    let rnd = this.randomOrder.get(itemId);
    if (typeof(rnd) === 'undefined') {
      rnd = Math.random();
      this.randomOrder.set(itemId, rnd);
    }
    return rnd;
  }

  public get voterCount(): number {
    return this.voterCnt;
  }
}
  
class Participant {
  public readonly name: string;
  private readonly db: Db;
  public readonly markCallback;

  private randomOrder: Map<string, number> = new Map();

  private addedItemCount: number = 0;

  constructor(http: HttpClient, urlKey: string, ownName: string, clean: boolean, admin: boolean, markCallback: Function) {
    this.name = ownName;
    this.markCallback = markCallback;
    this.db = new Db(http, urlKey);
    // this.db = new RTCDB('distEst.' + ownName, peer, clean);
    // this.db.on(['add', 'update'], 'items', false, () => this.invalidateCache());
    // this.db.on(['add', 'update'], 'topVotes', false, () => this.invalidateCache());
    // this.db.on(['add', 'update'], 'estimates', false, () => this.invalidateCache());

    if (clean && admin) {
      this.setDotsPerVoter(4);
    }
  }

  public getDotsPerVoter(): number {
    return this.db.get('dotsPerVoter', 'dotsPerVoter');
  }

  public setDotsPerVoter(dotsPerVoter: number): void {
    this.db.put('dotsPerVoter', 'dotsPerVoter', dotsPerVoter);
  }

  public addItem(trimmed: string): void {
    let obj : ItemDto;
    let colonIndex = trimmed.indexOf(':');
    if (colonIndex >= 0) {
      obj = {
        boldText: trimmed.substring(0, colonIndex + 1),
        text: trimmed.substring(colonIndex + 1)
      };
    } else {
      obj = {
        boldText: '',
        text: trimmed
      };
    }
    let key;
    do {
      key = this.name + this.addedItemCount;
      this.addedItemCount++;
    } while (this.db.get('items', key))
    this.db.put('items', key, obj);
  }

  public getState(): string {
    return this.db.get('state', 'state');
  }

  public setState(state: string): void {
    return this.db.put('state', 'state', state);
  }

  public connectTo(idToJoin: string): void {
    this.db.setUrlKey(idToJoin);
  }

  public hasNoTopVote(): boolean {
    return typeof(this.db.get('topVotes', this.name)) !== 'string';
  }

  public get voteSummary(): VoteSummary {
    return new VoteSummary(this.db, this.randomOrder);
  }

  voteForTop(itemIds: string[]) {
    this.db.put('topVotes', this.name, itemIds.join(','));
  }
}

class Db {
  private dbCache: DbDto = {
    time: 0,
    items: {},
    estimates: {},
    topVotes: {},
    state: {state: 'running'}
  };
  private getRunning: boolean = false;
  private putRunning: boolean = false;
  private putQueue: any[] = [];
  private lastGet: number = 0;

  constructor(
    private http: HttpClient,
    private urlKey: string
  ) {
    window.setInterval(() => {
      this.triggerGet();
    }, 2000);
  }
  
  setUrlKey(uk: string) {
    this.urlKey = uk;
  }

  public put(db: string, key: string, value: any) {
    let dca = this.dbCache as any;
    if (!dca[db]) {
      dca[db] = {}
    }
    dca[db][key] = value;
    console.log('put ' + db + ' ' + key + ' ' + value);
    let data = {
      urlKey: this.urlKey,
      db: db,
      key: key,
      value: value
    };
    this.putQueue.push(data);
    this.putFromQueue();
  }

  private putFromQueue() {
    if (this.putRunning) {
      return;
    }
    if (this.putQueue.length == 0) {
      return;
    }
    this.putRunning = true;
    let data = this.putQueue.shift();
    this.http.post('/putValue', data).subscribe((data: any) => {
      this.updateIfNewer(data);
      this.putRunning = false;
      this.putFromQueue();
    },
    (error: any) => {
      this.putRunning = false;
      this.putFromQueue();
      console.log('error ' + error);
    });
  }

  private updateIfNewer(data: any) {
    if (data.time > this.dbCache.time) {
      this.dbCache = data;
    }
  }
  
  public get(db: string, key: string) {
    this.triggerGet();
    let dbObj = (this.dbCache as any)[db];
    if (!dbObj) {
      return undefined;
    }
    return dbObj[key];
  }
  
  public forEach(db: string, f: (key: string, value: any) => void) {
    this.triggerGet();
    let obj : any = (this.dbCache as any)[db];
    if (!obj) {
      return;
    }
    Object.keys(obj).forEach(id => f(id, obj[id]));
  }  

  private triggerGet() {
    if (this.getRunning || this.putRunning) {
      return;
    }
    let now = new Date().getTime();
    if (now - this.lastGet < 1000) {
      return;
    }
    this.lastGet = now;
    this.getRunning = true;
    this.http.post('/getDb', {
      urlKey: this.urlKey
    }).subscribe((data: any) => {
      this.getRunning = false;
      if (!this.putRunning && this.putQueue.length == 0) {
        this.updateIfNewer(data);
      }
    },
    (error: any) => {
      this.getRunning = false;
      console.log('error ' + error);
    });
  }
}



export { Participant, VotesPerItem };
